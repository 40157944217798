import { insertScript } from './trackingHelper'
import { trackingConfig } from '../../trackingConfig'

declare global {
  interface Window {
    gtag?: (...args: unknown[]) => void
    dataLayer: unknown[][]
  }
}

let enabled = false

const gtag = (...args: unknown[]) => {
  if (!enabled) {
    return
  }

  window.gtag && window.gtag(...args)
}

export const loadGoogleRemarketing = () => {
  if (enabled) {
    return
  }

  enabled = true

  window.dataLayer = window.dataLayer || []

  window.gtag =
    window.gtag ||
    function () {
      // @ts-ignore
      window.dataLayer.push(arguments)
    }

  gtag('js', new Date())

  gtag('config', trackingConfig.googleRemarketing.trackingId, {
    anonymize_ip: true,
    allow_google_signals: false,
  })

  insertScript(
    `https://www.googletagmanager.com/gtag/js?id=${trackingConfig.googleRemarketing.trackingId}`
  )
}
