export const trackingConfig = {
  googleAnalytics: {
    anonymize: true,
    trackingId: 'UA-127299545-1',
  },
  googleRemarketing: {
    anonymize: true,
    trackingId: 'AW-796470827',
  },
  facebookPixel: {
    pixelId: '666492661362844',
  },
  linkedinInsight: {
    partnerId: '659425',
  },
}
